import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import TabSelector from '../../components/TabSelector';
import Title from '../../components/Title';
import './style.css';
import MeuCadastro from '../../components/MeuCadastro';
import GerenciarCadastrosContainer from '../../containers/Conta/GerenciarCadastrosContainer';
import { getAuthenticatedUser } from '../../services/userService';


class Conta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: ['PERFIL','CADASTROS'],
            selectedTab: 'PERFIL'
        }
        this.handleTabClick = this.handleTabClick.bind(this);
    }
    async componentDidMount() {
        const user = await getAuthenticatedUser();
        if(user.isAdmin){
            this.setState({tabs: ['PERFIL','CADASTROS']})
        } else {
            this.setState({tabs: ['PERFIL']})
        }
    }

    handleTabClick(event){
        const text =  event.target.innerText;
        this.setState({selectedTab: text});
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Title title={'CONTA'} />
                    </Col>
                </Row>
                <TabSelector
                    tabs={this.state.tabs}
                    selectedTab={this.state.selectedTab}
                    handleClick={this.handleTabClick}
                />
                <Row>
                    <Col>
                        {
                            this.state.selectedTab === 'PERFIL' ?
                            <MeuCadastro
                            />
                            :
                            null
                        }
                        {
                            this.state.selectedTab === 'CADASTROS' ?
                            <GerenciarCadastrosContainer
                            />
                            :
                            null
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Conta;
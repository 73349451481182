import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Farmacia from './pages/Farmacia';
import Atendimento from './pages/Atendimento';
import Home from './pages/Home';
import Logout from './pages/Logout';
import Conta from './pages/Conta';
import PDV from './pages/PDV';
import Credencial from "./pages/Credencial";
import Base from "./pages/Base";
import DetalhesFarmacia from "./pages/DetalhesFarmacia";

export default function RoutesManager() {
  return (
    <div style={{ maxWidth: 991 }}>
      <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route path="/conta">
              <Conta />
            </Route>
            <Route exact path="/dispensacao/:id">
              <DetalhesFarmacia />
            </Route>
            <Route exact path="/dispensacao">
              <Farmacia />
            </Route>
            <Route path="/atendimento">
              <Atendimento />
            </Route>
            <Route path="/pdv">
              <PDV />
            </Route>
            <Route path="/credenciais">
              <Credencial />
            </Route>
            <Route path="/base">
              <Base />
            </Route>
          </Switch>
      </Router>
    </div>
  );
}

import React, { useState } from 'react';
import Modal from 'react-modal';
import { FlexSB, Flex } from '../../App'
import './style.css'

export function InputAuton({ title, ...props }) {
    return (
        <div>
            <label className="my-input-label" >{title}</label>
            <input className="my-input" {...props} />
        </div>
    )
}

export function PrescriptedRemedysExchange({ changeSubscription, items, toAdd }) {
    if(typeof items?.map !== 'function') return null

    return items?.map((item) => {
        const { priceAtSubscriptionDate, ImageUrl, NameComplete, BrandName, Label, Nome, MenorPreco, name, price } = item
        const remedyName = (NameComplete || Label || Nome || name)?.slice(0, 20)
        const finalPrice = 'R$ ' + ((price || priceAtSubscriptionDate || MenorPreco || 0) * 1)?.toFixed(2)
        return (
            <div className="remedy-card-prescription" style={{ height: 80, marginTop: 8 }} onClick={() => changeSubscription(item)}>
                <FlexSB>
                    <Flex style={{ alignItems: 'center' }}>
                        <img className="remedy-image" src={ImageUrl} />
                        <div>
                            <p className="remedy-name">{remedyName}</p>
                            <p className="remedy-subname">{BrandName}</p>
                            <p>{toAdd?.find(e => item?.Id === e?.skuId) && 'selecionado'}</p>
                        </div>
                    </Flex>
                    <p className="remedy-price">{finalPrice}</p>
                </FlexSB>
            </div>
        )})
}

export function PrescriptedRemedys({ items, exchangeRemedy, toAdd, setToAdd }) {
    const [quantity, setQuantity] = useState({})
    if(typeof items?.map !== 'function') return null

    return items?.map(item => {
        const teste = toAdd?.find(e => {  
            console.log({ e })
            return item?.idPrescricaoMedicamento === e?.fatherId
        })
        const { priceAtSubscriptionDate, ImageUrl, NameComplete, Label, Nome, BrandName, MenorPreco } = teste || item
        return (
            <div className="remedy-card-prescription" style={{ marginTop: 8 }} onClick={() => { return { ...items, quantity } }}>
                <FlexSB>
                    <Flex style={{ alignItems: 'center' }}>
                        <img className="remedy-image" src={ImageUrl} />
                        <div>
                            <p className="remedy-name">{(NameComplete || Label || Nome)?.slice(0, 20)}</p>
                            <p className="remedy-subname">{BrandName}</p>
                            <button onClick={() => exchangeRemedy(item)} style={{ width: 332, height: 22, background: '#E7EBF1', borderRadius: 10 }}>Trocar medicamento</button>
                        </div>
                    </Flex>
                    <div>
                        <p className="remedy-price">R${((priceAtSubscriptionDate || MenorPreco) * quantity?.[Nome] || 0)?.toFixed(2)}</p>
                        <div>
                            <button
                                onClick={() => {
                                    const teste = {...quantity, ...{ [Nome]: quantity?.[Nome] >= 0 || quantity?.[Nome] === undefined ? (quantity?.[Nome] || 0) + 1 : 0 }}
                                    setQuantity(teste)
                                }}
                                style={{ marginRight: 6, border: '2px solid #C1CCDD', borderRadius: '50%', color: '#C1CCDD', fontWeight: 'bold', background: 'transparent' }}
                            >
                                +
                            </button>
                            {quantity?.[Nome] || 0}
                            <button
                                onClick={() => {
                                    const teste = {...quantity, ...{ [Nome]: quantity?.[Nome] >= 0 || quantity?.[Nome] === undefined ? (quantity?.[Nome] || 0) - 1 : 0 }}
                                    setQuantity(teste)
                                    // setToAdd([...toAdd, toAdd?.find(e => e?.skuId === item?.skuId)?.[0], quantity: item?. })
                                }}
                                style={{ marginLeft: 6, border: '2px solid #C1CCDD', borderRadius: '50%', color: '#C1CCDD', fontWeight: 'bold', background: 'transparent' }}
                            >
                                -
                            </button>
                        </div>
                    </div>
                </FlexSB>
            </div>
        )})
}

export function RemedyItems({ changeSubscription, items, getReceipt }) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const searchprescription = 'https://farmacias.nexodata.com.br/searchprescription'

    return items?.map(({id, pdv: { CodigoValidacao, Posologia }, priceAtSubscriptionDate, ImageUrl, NameComplete, UnitMultiplier, BrandName}) =>
        <div className="remedy-card">
            <FlexSB>
                <Flex style={{ alignItems: 'center' }}>
                    <img className="remedy-image" src={ImageUrl} />
                    <div>
                        <p className="remedy-labels">{UnitMultiplier} caixas</p>
                        <p className="remedy-name">{NameComplete?.slice(0, 20)}</p>
                        <p className="remedy-subname">{BrandName}</p>
                    </div>
                </Flex>
                <p className="remedy-price">R${(priceAtSubscriptionDate / 100)?.toFixed(2)}</p>
            </FlexSB>
            <div className="remedy-info-div">
                <p className="remedy-labels" style={{ marginBottom: 8 }}>Posologia</p>
                <p>{Posologia}</p>
                <FlexSB style={{ alignItems: 'center' }}>
                    <p className="remedy-labels">Código da Receita</p>
                    <a href={searchprescription} target="_blank"><div className="remedy-code"><p className="remedy-code-value">{CodigoValidacao}</p></div></a>
                </FlexSB> 
            </div>
            <button onClick={() => setIsModalOpen(true)} >excluir</button>
            <button onClick={() => getReceipt(CodigoValidacao)} >trocar</button>
            <ModalRemedyAdd isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} button1Text="Voltar" button2Text="Confirmar" button2Click={() => {changeSubscription({ id, type: 'delete' }); setIsModalOpen(false)}}>
                <p>
                    Tem certeza que desesja excluír esse medicamento? Após excluír não poderá ser adicionado novamente sem a apresentação de uma nova receita médica.
                </p>
            </ModalRemedyAdd>
        </div>
    )
}

export function ModalRemedyAdd({ children, onRequestClose, ...props }) {
    const { button1Click = onRequestClose, button1Text="Voltar", button2Click = onRequestClose, button2Text="Avancar", showButton1 = true, showButton2 = true } = props

    return (
        <Modal
            style={{ content: { width: 600, height: 550, top: 50, left: 50, background: '#F9F9FF', borderRadius: 30, padding: 0, position: 'relative' }}}
            onRequestClose={onRequestClose}
            {...{ ...props }}
        >
            <div style={{ padding: 40, minHeight: 451 }}>
                {children}
            </div>
            <div style={{ bottom: 0, left: 0, width: '100%', background: '#FFFFFF', height: 97, position: 'absolute', display: 'flex', justifyContent: 'space-between', padding: 20, position: 'sticky', borderRadius: 30 }}>
                {showButton1 && <button onClick={button1Click}>{button1Text}</button>}
                {showButton2 && <button onClick={button2Click}>{button2Text}</button>}
            </div>
        </Modal>
    )
}
import React from 'react';
import {Container, Row, Button} from 'react-bootstrap';
import './styles.css';
import UsuariosLista from '../UsuariosLista';
import CriarEditarUsuario from '../CriarEditarUsuario';

class GerenciarCadastros extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        }
    }
    componentDidMount() {

    }

    render() {
        return (
            <Container fluid className='gerenciar-cadastros-container'>
                <Row>
                    <Button onClick={() => this.setState({showModal: true})}> 
                        Novo cadastro
                    </Button>
                </Row>
                <Row>
                    <UsuariosLista
                        lista={this.props.usuarios}
                        onUserUpdate={this.props.onUserUpdate}
                    />
                </Row>
                {
                    this.state.showModal ? 
                    <CriarEditarUsuario
                        show={this.state.showModal}
                        handleClose={() => this.setState({showModal: false})}
                        onUserUpdate={this.props.onUserUpdate}
                    />
                    :
                    null
                }
            </Container>
        );
    }
}

export default GerenciarCadastros;
import * as React from "react";

function SvgFrameUser(props) {
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.967 20.77s-3.165 2.656-2.556 6.46c.61 3.805 16.31 3.58 16.792 0 .482-3.58-2.438-6.228-2.438-6.228"
        stroke="#132E59"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.795 19.23a4.615 4.615 0 100-9.23 4.615 4.615 0 000 9.23z"
        stroke="#30D4E3"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={1}
        y={1}
        width={38}
        height={38}
        rx={19}
        stroke="#132E59"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgFrameUser;

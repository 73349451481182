import * as React from "react";

function SvgLogoNexo(props) {
  return (
    <svg
      width={82}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.848 8.147h1.43v7.627h-.54a.709.709 0 01-.563-.276l-3.772-4.86v5.136h-1.43V8.16h.82a.71.71 0 01.559.273l3.502 4.45-.006-4.735zM36.54 15.775h-5.3V8.158h5.3a.376.376 0 01.378.379V9.55H32.66v1.751h4.107v1.339H32.66v1.72h4.262v1.035a.38.38 0 01-.381.379zM43.698 8.374a.486.486 0 01.403-.216h1.313v.066l-2.568 3.7 2.643 3.807v.044h-1.346a.485.485 0 01-.402-.213l-1.794-2.65-1.796 2.645a.486.486 0 01-.4.213h-1.347v-.044l2.633-3.807-2.557-3.695v-.066h1.312a.483.483 0 01.403.216l1.756 2.623 1.747-2.623zM53.788 12.01c-.022 1.97-1.224 3.94-3.89 3.94-2.666 0-3.9-1.926-3.9-3.929 0-2.002 1.279-4.015 3.9-4.015s3.91 2.013 3.89 4.005zm-6.381.033c.033 1.251.703 2.588 2.491 2.588 1.789 0 2.46-1.35 2.48-2.601.023-1.284-.693-2.72-2.48-2.72s-2.524 1.45-2.491 2.732zM61.869 11.88c.043 1.936-1.191 3.895-3.845 3.895h-2.569V8.158h2.569c2.564 0 3.798 1.85 3.845 3.722zm-5.828-3.167v6.491h1.983c2.253 0 3.292-1.697 3.26-3.329-.032-1.59-1.072-3.167-3.26-3.167l-1.983.005zM69.104 15.508l-.716-1.605H63.86l-.703 1.602a.452.452 0 01-.41.27h-.337l3.397-7.617h.64l3.401 7.617h-.337a.449.449 0 01-.407-.267zm-.937-2.148L66.13 8.724l-2.037 4.636h4.074zM71.735 8.691h-2.74V8.53a.361.361 0 01.358-.359h5.35a.358.358 0 01.359.36v.161H72.32v7.084h-.586V8.691zM80.901 15.503l-.712-1.6H75.66l-.703 1.598a.458.458 0 01-.419.274h-.33l3.397-7.617h.64l3.396 7.617h-.33a.46.46 0 01-.41-.272zm-.937-2.143l-2.037-4.636-2.036 4.636h4.073z"
        fill="#0D0C23"
      />
      <path
        d="M18.193 1.048L4.347 21.91a2.682 2.682 0 01-2.225 1.196H.352a.35.35 0 01-.352-.37.354.354 0 01.06-.18l13.845-20.86A2.678 2.678 0 0116.131.5h1.77a.35.35 0 01.35.37.355.355 0 01-.058.178z"
        fill="#30D4E3"
      />
      <path
        d="M15.973 19.513H13.63a2.333 2.333 0 01-1.947-1.047L2.743 4.998a.436.436 0 01.36-.676h2.14A2.334 2.334 0 017.19 5.369l9.018 13.704a.284.284 0 01-.234.44h-.002zM3.17 4.793l8.901 13.41a1.871 1.871 0 001.558.837h1.991L6.793 5.63a1.87 1.87 0 00-1.558-.837H3.17z"
        fill="#0D0C23"
      />
      <path
        opacity={0.8}
        d="M8.968 9.136L.059 22.556a.354.354 0 00.292.55h1.771a2.668 2.668 0 002.225-1.196l6.55-9.867-1.93-2.907z"
        fill="#30D4E3"
      />
    </svg>
  );
}

export default SvgLogoNexo;

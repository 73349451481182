import React from 'react';
import {Container, Row} from 'react-bootstrap';
import './styles.css';

class UsuarioCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    componentDidMount() {

    }

    render() {
        return (
            <Container fluid className='usuario-card-container'
                 onClick={() => this.props.handleClick(this.props.usuario)}>
                <Row className='usuario-card-title'>
                    <div>
                        <strong>{this.props.usuario.nome}</strong>
                    </div>
                    {
                        this.props.usuario.isAdmin ?
                        <div>admin</div>
                        :
                        ''
                    }
                </Row>
            </Container>
        );
    }
}

export default UsuarioCard;
import React from 'react';
import { getUsers } from '../../services/userService';
import GerenciarCadastros from '../../components/GerenciarCadastros';

class GerenciarCadastrosContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usuarios: [],
            loading: false,
        }
        this.getUserList = this.getUserList.bind(this);
    }

    async getUserList(){
        this.setState({loading: true});
        let resultados = [];
        try {
            resultados = await getUsers();
        }
        catch(err) {
            console.log("erro ao buscar usuários cadastrados.", err)
        }
        this.setState({usuarios: resultados, loading: false});
    }

    async componentDidMount(){
        await this.getUserList();
    }   

    render() {
        return (
            <GerenciarCadastros
                usuarios={this.state.usuarios}
                loading={this.state.loading}
                onUserUpdate={this.getUserList}
            />
        );
    }
}

export default GerenciarCadastrosContainer;
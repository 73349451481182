import { myAxios } from './axios';

export const searchDoctors = async (searchObj) => {
    const response = await  myAxios.post('/doctor/search',searchObj);
    return response.data;
}

export const updateDoctorDocument = async (doctorId, document) => {
  const response = await  myAxios.put(`/doctor/${doctorId}/document?document=${document}`);
  return response.data;
}

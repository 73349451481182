import React from 'react';
import {Container} from 'react-bootstrap';
import logo from '../../assets/images/coding-freak.gif';

class Base extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {

    }

    render() {
        return (
            <Container>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <img style={{maxWidth:'400px'}} src={logo} alt="developing..." />
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <h3>Em desenvolvimento...</h3>
                </div>
            </Container>
        );
    }
}

export default Base;
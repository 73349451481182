import React from 'react';
import BuscaPrescricao from '../../components/BuscaPrescricao';
import { searchPrescriptions } from '../../services/prescriptionService';

class BuscaPrescricaoContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lista: [],
            loading: false,
        }
        this.getPrescricoes = this.getPrescricoes.bind(this);
    }

    async getPrescricoes(searchObj){
        this.setState({loading: true});
        let resultados = []
        if(!!searchObj){
            try {
                resultados = await searchPrescriptions(searchObj);
            } catch(err){
                alert("Erro ao buscar prescrições.")
            }
        }
        this.setState({lista: resultados, loading: false});
    }

    render() {
        return (
            <BuscaPrescricao
                lista={this.state.lista}
                loading={this.state.loading}
                onPrescriptionUpdate={this.getPrescricoes}
            />
        );
    }
}

export default BuscaPrescricaoContainer;
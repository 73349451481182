import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { getFullSubscription, getPrescription, postSubscription, updateAddress } from '../../services/subscription'
import { FlexSB, Flex } from '../../App'
import './style.css'
import { enviromentVariables } from '../../vars'
import { InputAuton, ModalRemedyAdd, PrescriptedRemedys, PrescriptedRemedysExchange, RemedyItems } from './components'
import { SearchBox } from '../Farmacia'

function ScrollView({ children }) {
    return (
        <div style={{ overflow: 'hidden', overflowY: 'scroll' }}>{children}</div>
    )
}

export function DetalhesFarmacia() {
    const { id } = useParams()
    const [sub, setSub] = useState({})
    const [loading, setLoading] = useState(true)
    const [remedyAdd, setRemedyAdd] = useState(false)
    const [remedyCode, setRemedyCode] = useState(false)
    const [remedys, setRemedys] = useState(null)
    const [exchangeRemedy, setExchangeRemedy] = useState([])
    const [receiptCode, setReceiptCode] = useState("")
    const [toAdd, setToAdd] = useState([])

    useEffect(async () => {
        const subscriptions = await getFullSubscription({ id })
        setSub(subscriptions)
        setLoading(false)
    }, [])

    if (loading) return <div>carregando</div>

    const {
        status,
        createdAt,
        nextPurchaseDate,
        shippingAddress,
        purchaseSettings,
        fullPrice,
        items,
    } = sub
    const subscriptionDate = new Date(createdAt)?.toLocaleDateString('pt-BR')
    const nextPurchaseDatePTBR = new Date(nextPurchaseDate)?.toLocaleDateString('pt-BR')
    const translatedStatus = [{ status: 'PAUSED', label: 'PAUSADA' },{ status: 'ACTIVE', label: 'ATIVA' }, { status: 'CANCELED', label: 'CANCELADA' }].filter(e => {
        console.log({ e }, e?.status === status)
        return e?.status === status
    })?.[0]?.label
    console.log({ translatedStatus }, status)
    const showPauseButton = status === 'ACTIVE'
    const showUnpauseButton = status === 'PAUSED'
    const showJumpButton = status === 'ACTIVE' && !sub?.isSkipped
    const showUnjumpButton = status === 'ACTIVE' && sub?.isSkipped === true
    const onClickGoBack = () => window.location.replace('/dispensacao/')
    const originalOrderId = items?.[0]?.originalOrderId
    const linkOrder =  enviromentVariables.nexodataCheckoutOrders + originalOrderId

    const selectedItem = exchangeRemedy
    const filterEqualExchange = selectedItem?.exchange

    const changeSubscription = async (data) => {
        await postSubscription(id, data)
        window.location.reload();
    }
    const onChangeAddress = async e => {
        e.preventDefault();
        const data = Array.prototype.reduce.call(e?.target?.elements,
            (acum, elem) => {
                if(elem.value === "") return acum
                acum[elem.name] = elem.value
                return acum
            }, {})

        await updateAddress(id, { ...shippingAddress, ...data })
    }

    const getReceipt = async search => {
        const teste = await getPrescription(search)
        setReceiptCode(search)
        setRemedyCode(false)
        setRemedyAdd(true)
        setToAdd([])
        setRemedys(teste?.data?.Itens)
    }

    return (    
        <div>
            <ModalRemedyAdd
                isOpen={remedyCode}
                onRequestClose={() => setRemedyCode(false)}
                showButton2={false}
            >
                <p style={{ fontSize: 30, textAlign: 'center', fontWeight: 'bold' }}>Insira o código da receita</p>
                <p style={{ fontSize: 14, textAlign: 'center', marginBottom: 40, marginTop: 10 }}>Pergunte ao paciente qual o código da Receita Médica dele</p>
                <SearchBox
                    title="NX00YZC"
                    placeholder="Insira o código da receita"
                    onSearch={async search => {
                        if(!/NX[a-zA-Z0-9]{5}/.test(search)) return alert('codigo no formato incorreto, NX0AO9P')
                        getReceipt(search)
                    }} />
            </ModalRemedyAdd>
            <ModalRemedyAdd
                isOpen={remedyAdd}
                button1Click={() => {
                    setRemedyCode(true);
                    setRemedyAdd(false)
                }}
                button2Click={() => toAdd?.map(e => changeSubscription({ quantity: 2, skuId: e?.Id }))}
                button2Text="Adicionar"
                onRequestClose={() => setRemedyAdd(false)}
            >
                <div>
                    <h3>Inserindo nova Receita</h3>
                    <FlexSB>
                        <p>Código da receita</p>
                        <p>{receiptCode}</p>
                    </FlexSB>
                    <p>Medicamentos prescritos</p>
                    <ScrollView>
                        <PrescriptedRemedys
                            exchangeRemedy={(e) => setExchangeRemedy(e)}
                            idSubscription={id}
                            {...{ items: remedys, toAdd }} />
                    </ScrollView>
                </div>
            </ModalRemedyAdd>
            <ModalRemedyAdd
                isOpen={selectedItem?.idPrescricaoMedicamento}
                button2Text="Salvar"
                button1Click={() => {setExchangeRemedy(-1); setRemedyCode(false); setRemedyAdd(true) }}
            >
                <div>
                    <h3>Trocar medicamento</h3>
                    <p>Item no carrinho</p>
                    <ScrollView>
                        <PrescriptedRemedysExchange idSubscription={id} items={[selectedItem]} changeSubscription={teste => console.log({ teste })} />
                    </ScrollView>
                    <p>Itens disponiveis para substituição</p>
                    <ScrollView>
                        <PrescriptedRemedysExchange idSubscription={id} toAdd={toAdd} items={filterEqualExchange} Posologia={selectedItem?.Posologia} 
                        changeSubscription={(item) => {
                            const filteredAdd = toAdd?.filter(e => {
                                return e?.fatherId !== selectedItem?.idPrescricaoMedicamento
                            })
                            setToAdd([...filteredAdd, { ...item, fatherId: selectedItem?.idPrescricaoMedicamento, quantity: 1 }]); setExchangeRemedy(-1); setRemedyCode(false); setRemedyAdd(true)}
                        } />
                    </ScrollView>
                </div>
            </ModalRemedyAdd>

            <FlexSB>
                <div>
                    <h2>Detalhes da assinatura</h2>
                    {originalOrderId && <p>Link pedido: <a href={linkOrder} target="_blank">{originalOrderId || '-'}</a></p>}
                </div>
                <p>status da assinatura: {translatedStatus}</p>
            </FlexSB>
            <FlexSB style={{ margin: '18px 0px' }}>
                {showPauseButton && <button onClick={() => changeSubscription({ status: 'PAUSED' })}>Pausar</button>}
                {showUnpauseButton && <button onClick={() => changeSubscription({ status: 'ACTIVE' })}>Reativar</button>}
                {showJumpButton && <button onClick={() => changeSubscription({ isSkipped: true })}>Pular</button>}
                {showUnjumpButton && <button onClick={() => changeSubscription({ isSkipped: false })}>Não pular</button>}
                {translatedStatus === 'CANCELED' && <button onClick={() => changeSubscription({ status: 'CANCELED' })}>Cancelar</button>}
            </FlexSB>
            <FlexSB>
                    <InputAuton disabled value={subscriptionDate} title="Contratação:"/>
                    <InputAuton disabled value={nextPurchaseDatePTBR} title="Próxima entrega:" />
                    <InputAuton disabled value="-" title="Frequência de entrega:" />   
                    <InputAuton disabled value={'R$ ' + fullPrice} title="Valor da compra:" />
                </FlexSB>
            <Flex>
                <div style={{ width: '50%', marginRight: 10 }}>
                    <InputAuton disabled value={sub?.customerEmail} title="Email do cliente:" />
                    <InputAuton disabled value={shippingAddress?.receiverName} title="Nome do cliente:" />
                    <form onSubmit={onChangeAddress}>
                        <button type="submit">salvar</button>
                        <InputAuton name="street" placeholder={shippingAddress?.street} title="Endereço:" />
                        <InputAuton name="number" placeholder={shippingAddress?.number} title="Número:" />
                        <InputAuton name="neighborhood" placeholder={shippingAddress?.neighborhood} title="Bairro:" />
                        <InputAuton name="complement" placeholder={shippingAddress?.complement} title="Complemento:" /> 
                        <InputAuton name="city" placeholder={shippingAddress?.city} title="Cidade:" />
                        <InputAuton name="state" placeholder={shippingAddress?.state} title="UF:" />
                    </form>
                </div>
                <div style={{ width: '50%', marginLeft: 10 }}>
                    <InputAuton disabled value={purchaseSettings?.paymentSystemName} title="Forma de pagamento:" />
                    <InputAuton disabled value={purchaseSettings?.cardNumber} title="Número do cartão:" />
                    {items?.[0]?.id && (
                        <>
                            <button onClick={() => setRemedyCode(true)}>Adicionar medicamento</button>
                            <div className="remedy-list-div">
                                <RemedyItems {...{ getReceipt, changeSubscription, Posologia: selectedItem?.Posologia, items: Object.values(items) }} />
                            </div>
                        </>
                    )}
                </div>
            </Flex>
        </div>
    )
}

export default DetalhesFarmacia

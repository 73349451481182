import React from 'react'
import { Flex } from '../../App'
import './styles.css'
import ReactTooltip from 'react-tooltip'

export const statusToSearch = [
    'ACTIVE',
    'PAUSED',
    'CANCELED',
    'EXPIRED'
]

export const  validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

function transformSubscriptionItemsIntoHtml(items) {
    const html = items.reduce((acum, { ProductName }) => {
        if(!ProductName) return ''
        return acum + ProductName?.slice(0, 10) + '...<br/>'
    }, '')
    return html
}

export function Select({ active, ...props }) {
    const className = `sucess-buble ${!active && 'hidden'}`

    return (
        <button className="my-chip-button" {...{ ...props }}>
            <span {...{ className }} />
            {props.children}
        </button>
    )
}

export const LineSearch = ({ onClick, ...props }) => {
    const { customerEmail, status, createdAt, lastPurchaseDate, items } = props?.sub
    const subscriptionDate = new Date(createdAt)?.toLocaleDateString('pt-BR')
    const lastPurchaseDateFormated = new Date(lastPurchaseDate)?.toLocaleDateString('pt-BR')

    return (
        <>
            <div className="my-row" onClick={() => onClick(props.sub)}>
                <div>{customerEmail}</div>
                <ReactTooltip html={true} />
                <div data-tip={transformSubscriptionItemsIntoHtml(items)} >{items.length > 0 ? 'medicamentos' : 'medicamento'} </div>
                <div>{subscriptionDate}</div>
                <div>{lastPurchaseDateFormated}</div>
                <div>{status}</div>
            </div>
        </>
    )
}

export const LineSearchSimplified = ({ onClick, ...props }) => {
    const { customerEmail, items } = props?.sub

    return (
        <>
            <div style={{ marginTop: 8 }} className="my-row" onClick={() => onClick(props.sub)}>
                <div>{customerEmail}</div>
                <ReactTooltip html={true} />
                <div data-tip={transformSubscriptionItemsIntoHtml(items)} >{items?.length} medicamentos</div>
            </div>
        </>
    )
}

export function SelectStatus({ list, onChange, selected }) {
    return (
        <>
            {list.map(status => {
                return (
                    <Select
                        children={status}
                        active={selected === status}
                        onClick={() => onChange(() => selected === status ? '' : status)}
                    />
                )})}
        </>
    )
}

export const FilterBox = ({ statusToSearch, setSearchStatusSelected, searchStatusSelected }) => {
    return (
        <div className="filter-box">
            <p>filtrar por:</p>
            <Flex>
                <SelectStatus
                    list={statusToSearch}
                    onChange={setSearchStatusSelected}
                    selected={searchStatusSelected}
                />
            </Flex>
        </div>
    )
}

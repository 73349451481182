import { myNodeAxios } from './axios'

export const getSubscriptions = async ({ customerEmail, status, id } = {}) => {
    const response = await myNodeAxios.get(`subscription/${id || ''}`, { params: { customerEmail, status } })
    return response.data
}

export const getFullSubscription = async ({ customerEmail, status, id } = {}) => {
    const response = await myNodeAxios.get(`subscription/${id || ''}/full`, { params: { customerEmail, status } })
    return response.data
}

export const postSubscription = async (id, body) => {
    const response = await myNodeAxios.post(`subscription/${id || ''}/item`, body)
    return response.data
}

export const updateAddress = async (id, body) => {
    const response = await myNodeAxios.put(`/subscription/document/${body?.id || ''}`, body)
    return response.data
}

export const getOrder = async order => {
    const response = await myNodeAxios.get('subscription/order/' + order)
    return response.data
}

export async function getPrescription(code) {
    let remedy
    try {
        remedy = await myNodeAxios.get(`/prescription/${code}/full`)
    } catch(error) {
        console.log(error)
    }
    return remedy
}

import React from 'react';
import './styles.css';

class Title extends React.Component {
    render() {
      return (
        <div className='title-container'>
            <h3>
                {this.props.title}
            </h3>
        </div>);
    }
  }

export default Title;
import React from 'react';
import './styles.css';

class TabSelector extends React.Component {
    render() {
        return (
            <div className='tab-container'>
                {
                    this.props.tabs.map(tab => (
                            <div key={tab} className={`tab-item ${this.props.selectedTab === tab ? 'selected': ''}`} 
                                onClick={this.props.handleClick}>
                                {tab}
                            </div>
                    ))
                }   
            </div>
        );
    }
}

export default TabSelector;
import React from 'react';
import {Container, Row, Col, FormControl, InputGroup, Button } from 'react-bootstrap';
import Title from '../../components/Title';
import Loading from '../../components/Loading';
import { getAuthenticatedUser } from '../../services/userService';
import { searchPrescriptionForPDV } from '../../services/prescriptionService';
import './styles.css'


class PDV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: '',
            prescription: null,
            resultsFor: '',
            loading: false
        }
        this.handleSearch =  this.handleSearch.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }

    handlePageClick(page){
        window.location.href=`/${page}`;
    }

    handleInput(e, state){
        this.setState({[state]: e.target.value}, () => {
            const buttonEnabled = !!this.state.codigo || !!this.state.email || !!this.state.celular || !!this.state.nome || !!this.state.idPaciente;
            this.setState({buttonEnabled});
        });
    }

    async componentDidMount() {
        const user = await getAuthenticatedUser();
        this.setState({availableFeatures: user.features})
    }

    async handleSearch(){
        this.setState({loading: TextTrackCueList})
        const payload = {
            "orderId": this.state.orderId
        }
        const prescription = await searchPrescriptionForPDV(payload);
        this.setState({prescription, orderId: '', resultsFor: this.state.orderId, loading: false});
    }

    formatEndereco(endereco){
        if(!endereco){
            return ''
        }
        return endereco.Endereco1 + " - " + endereco.Endereco2 + " - " + endereco.Bairro + " - " + endereco.Cidade + " - " + endereco.Estado  
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Title title={'Conferência de receita de pedidos:'} />
                    </Col>
                </Row>
                <Row>
                    <Col md='5' sm='12' xs='5'>
                            <div className='form-container'>
                                <InputGroup>
                                    <FormControl
                                        value={this.state.orderId}
                                        placeholder='Código do pedido'
                                        onChange = {(e) => this.handleInput(e,'orderId')}
                                    />   
                                </InputGroup>
                            </div>
                    </Col>
                    <Col md='auto' sm='auto' xs='auto'>
                            <Button
                                className="btn"
                                variant="info"
                                block
                                onClick={this.handleSearch}
                                disabled={!this.state.orderId}
                                label={'BUSCAR'}>
                                    BUSCAR
                            </Button>
                    </Col>
                </Row>    
                {
                        this.state.loading ? 
                        <Row>
                            <Loading/>
                        </Row>
                        :
                        null
                }    
                <Row style={{paddingTop: '40px'}}>
                    <Col md='auto' sm='auto' xs='auto'>
                                {
                                    this.state.prescription === null ?
                                    <h4> Nenhuma receita listada </h4>
                                    :
                                    <div>
                                        <h4> Mostrando resultados para: {this.state.resultsFor} </h4>
                                        <div className='prescricao-container'>
                                            <span>DATA PRESCRIÇÃO: <strong>{this.state.prescription.DataCriacao}</strong></span>
                                            <span>CÓDIGO DA RECEITA: <strong>{this.state.prescription.CodigoValidacao}</strong></span>
                                            {
                                                this.state.prescription.PrescricaoAssinada ?
                                                <span className='card-receita-assinada'>RECEITA ASSINADA</span>:
                                                <span className='card-receita-n-assinada'>RECEITA NÃO ASSINADA</span>
                                            } 
                                            <div className='prescription-label-container'>   
                                                <div className='prescription-label'>
                                                    <h3> Paciente </h3>
                                                </div>    
                                                <div>
                                                    <span>Nome: {this.state.prescription.Paciente.Nome}</span>                                                
                                                </div>     
                                                <div>
                                                    <span>Idade: {this.state.prescription.Paciente.Idade}</span>
                                                </div>
                                                <div>
                                                    <span>Telefone: {this.state.prescription.TelefoneCelular}</span>
                                                </div>           
                                                <div>
                                                    <span>Sexo: {this.state.prescription.Paciente.Sexo}</span>
                                                </div> 
                                                <div>
                                                    <span>Endereço: {this.formatEndereco(this.state.prescription.Paciente.Endereco)}</span>
                                                </div> 
                                            </div> 
                                            <div className='prescription-label-container'>   
                                                <div className='prescription-label'>
                                                    <h3> Prescritor </h3>
                                                </div>    
                                                <div>
                                                    <span>Nome: {this.state.prescription.Prescritor.Nome}</span>                                                
                                                </div>     
                                                <div>
                                                    <span>CPF: {this.state.prescription.Prescritor.Documento}</span>
                                                </div>    
                                                <div>
                                                    <span>{this.state.prescription.Prescritor.Conselho}:  {this.state.prescription.Prescritor.Numero}-{this.state.prescription.Prescritor.UF} </span>
                                                </div> 
                                                <div>
                                                    <span>Contato Estabelecimeto: {this.state.prescription.Estabelecimento.Contato.TelefoneComercial}</span>
                                                </div> 
                                            </div> 
                                            <div className='prescription-label-container'>   
                                                <div className='prescription-label'>
                                                    <h3> Itens Estruturados </h3>
                                                </div>    
                                                {
                                                    this.state.prescription.Itens.map((item) => (
                                                        <div className='prescricao-item-container'>
                                                            <div><strong> {item.Label} - Qtd: {item.Quantidade} </strong> </div>
                                                            <div>{item.Posologia}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>                     
                                            <Button onClick={()=> window.open(this.state.prescription.PDFUrl, "_blank")}>
                                                BAIXAR RECEITA
                                            </Button>
                                        </div>
                                    </div>
                                }
                    </Col>    
                </Row>  
            </Container>
        );
    }
}

export default PDV;
import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Title from '../../components/Title';
import base from '../../assets/images/base.png';
import credentials from '../../assets/images/credentials.png';
import pos from '../../assets/images/pos.png';
import dispensacao from '../../assets/images/dispensacao.png';
import settings from '../../assets/images/settings.png';
import atendimento from '../../assets/images/atendimento.png';
import './styles.css';
import { getAuthenticatedUser } from '../../services/userService';


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            availableFeatures: []
        }
    }

    handlePageClick(page){
        window.location.href=`/${page}`;
    }

    async componentDidMount() {
        const user = await getAuthenticatedUser();
        this.setState({availableFeatures: user.features})
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Title title={'Bem-vindo'} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5>Acesse nossas páginas:  </h5>
                    </Col>
                </Row>
                <Row>
                    {
                        this.state?.availableFeatures?.includes("ATENDIMENTO") ?
                        <Col>
                            <div className='page-icon-container' onClick={() => this.handlePageClick('atendimento')}>
                                <div>
                                    <img className='page-icon' src={atendimento} alt="aten" />
                                </div>                            
                                <div>
                                    <span>Atendimento</span>
                                </div>                            
                            </div>
                        </Col>
                        :
                        null
                    }
                    {
                        this.state?.availableFeatures?.includes("DISPENSAÇÃO") ?
                        <Col>
                            <div className='page-icon-container' onClick={() => this.handlePageClick('dispensacao')}>
                                <div>
                                    <img className='page-icon' src={dispensacao} alt="aten" />
                                </div>                            
                                <div>
                                    <span>Dispensação</span>
                                </div>
                            </div>
                        </Col>
                        :
                        null
                    }
                    {
                        this.state?.availableFeatures?.includes("BASE") ?
                        <Col>
                            <div className='page-icon-container' onClick={() => this.handlePageClick('base')}>
                                <div>
                                    <img className='page-icon'  src={base} alt="aten" />
                                </div>
                                <div>
                                    <span>Base</span>
                                </div>                            
                            </div>
                        </Col>
                        :
                        null
                    }
                    {
                        this.state?.availableFeatures?.includes("CREDENCIAIS") ?
                        <Col>
                            <div className='page-icon-container' onClick={() => this.handlePageClick('credenciais')}>
                                <div>
                                    <img className='page-icon' src={credentials} alt="aten" />
                                </div>
                                <div>
                                    <span>Credenciais</span>    
                                </div>
                            </div>
                        </Col>
                        :
                        null
                    }
                    {
                        this.state?.availableFeatures?.includes("PDV") ?
                        <Col>
                            <div className='page-icon-container' onClick={() => this.handlePageClick('pdv')}>
                                <div>
                                    <img className='page-icon'  src={pos} alt="pdv" />
                                </div>
                                <div>
                                    <span>PDV</span>
                                </div>                            
                            </div>
                        </Col>
                        :
                        null
                    }
                    <Col>
                        <div className='page-icon-container' onClick={() => this.handlePageClick('conta')}>
                            <div>
                                <img className='page-icon' src={settings} alt="aten" />
                            </div>
                            <div>
                                <span>Conta</span>
                            </div>                            
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Home;
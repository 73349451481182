import React from 'react';
import {Container} from 'react-bootstrap';
import MedicoCard from '../MedicoCard';


class MedicosLista extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Container>
                {this.props.lista.map(medico => (
                    <MedicoCard 
                        key={Math.random()}
                        medico={medico}
                        onDoctorUpdate={this.props.onDoctorUpdate}
                    />
                ))}
            </Container>
        );
    }
}

export default MedicosLista;
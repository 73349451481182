import React from 'react';
import SvgFrameUser from '../../assets/svg/FrameUser';
import SvgLogoNexo from '../../assets/svg/LogoNexo';
import './styles.css';

function Navbar({ isSignedIn, userName }) {
    return (
        <div className='menu-container' style={{ justifyContent: isSignedIn ? 'space-between' : 'center' }}>
            {isSignedIn && <h1>Auton</h1>}
            <SvgLogoNexo />
            {isSignedIn && <a href="/conta">Operador(a), {userName}<SvgFrameUser /></a>}
        </div>
    );
}

export default Navbar;

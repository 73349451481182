import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import './styles.css';
import { BsFillUnlockFill } from 'react-icons/bs';
import { unlockPrescrition } from '../../services/prescriptionService';

class PrescricaoCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unlockModal: false,
            loading: false,
        }
    }

    statusColor(status){
        status = status.toUpperCase();
        if(status === "BLOQUEADA"){
            return 'red';
        }
        
        if(status === "INICIADA") {
            return 'orange';
        } 

        if(status === "EMITIDA") {
            return 'green';
        }
    }

    async handleUnlock(CodigoValidacao){
        this.setState({loading: true})
        await unlockPrescrition(CodigoValidacao);
        this.setState({unlockModal: false, loading: false})
        this.props.onPrescriptionUpdate();
    }

    render() {
        return (
            <div className='prescricao-card-container'>
                <div className='prescricao-card-title'>
                    <div>
                        <strong>Código:</strong> {this.props.prescricao.CodigoValidacao}
                    </div>
                    <div>
                        <strong>Data:</strong> {this.props.prescricao.DataModificacao}
                    </div>
                </div>
                <div className='prescricao-card-body'>
                    <div>
                        <strong>Paciente:</strong> {this.props.prescricao.Paciente.Nome}
                    </div>
                    <div>
                        <strong>ID:</strong> {this.props.prescricao.Paciente.IdPaciente}
                    </div>
                    <div>
                        <strong>Celular:</strong> {this.props.prescricao.Paciente.Celular}
                    </div>
                    <div>
                        <strong>Email:</strong> {this.props.prescricao.Paciente.Email}
                    </div>
                    <div>
                        <strong>Médico:</strong> {this.props.prescricao.Medico.Nome}
                    </div>
                </div>
                <div className='prescricao-card-footer'>
                    <div>
                        <span style={{color: 'grey', fontWeight: '700'}}>STATUS:</span>
                        <span style={{color: this.statusColor(this.props.prescricao.Status), paddingLeft:'5px'}}>
                            {this.props.prescricao.Status}
                        </span>
                    </div>
                    <div>
                        {
                            this.props.prescricao.Status.toUpperCase() === "BLOQUEADA" ?
                            <Button variant="light" onClick={() => this.setState({unlockModal: true})}>
                                <BsFillUnlockFill />
                            </Button>
                            :
                            null
                        }
                    </div>
                </div>
                {
                    this.state.unlockModal ? 
                    <Modal show={this.state.unlockModal} onHide={() => this.setState({unlockModal: false})}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Confirmar desbloqueio da receita?
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='prescricao-card-unlock-buttons'>
                                <Button variant="light" onClick={() => this.setState({unlockModal: false})}>
                                    CANCELAR
                                </Button>
                                <Button disabled={this.state.loading} variant="danger" onClick={() => this.handleUnlock(this.props.prescricao.CodigoValidacao)}>
                                    {this.state.loading ? "SIM..." : "SIM"}
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                  :
                  null
                }
            </div>
        );
    }
}

export default PrescricaoCard;
import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import TabSelector from '../../components/TabSelector';
import Title from '../../components/Title';
import './styles.css';
import BuscaPrescricaoContainer from '../../containers/Atendimento/BuscaPrescricaoContainer';
import BuscaMedicoContainer from '../../containers/Atendimento/BuscaMedicoContainer';

class Atendimento extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: ['BUSCA','CADASTRO MÉDICO'],
            selectedTab: 'BUSCA'
        }
        this.handleTabClick = this.handleTabClick.bind(this);
    }
    componentDidMount() {

    }

    handleTabClick(event){
        const text =  event.target.innerText;
        this.setState({selectedTab: text});
    }


    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <Title title={'Atendimento'} />
                    </Col>
                </Row>
                <TabSelector
                    tabs={this.state.tabs}
                    selectedTab={this.state.selectedTab}
                    handleClick={this.handleTabClick}
                />
                <Row>
                    <Col>
                        {
                            this.state.selectedTab === 'BUSCA' ?
                            <BuscaPrescricaoContainer                            
                            />
                            :
                            null
                        }
                        {
                            this.state.selectedTab === 'CADASTRO MÉDICO' ?
                            <BuscaMedicoContainer
                            />
                            :
                            null
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Atendimento;
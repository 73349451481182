import React from 'react';
import {Container, Row} from 'react-bootstrap';
import CriarEditarUsuario from '../CriarEditarUsuario';
import Loading from '../Loading';
import UsuarioCard from '../UsuarioCard';
import './styles.css';

class UsuariosLista extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: null
        }

    }
    componentDidMount() {

    }

    render() {
        return (
            <Container fluid className='usuarios-lista-container'>
                {
                    this.props.loading ? 
                   <Loading/>
                    :
                    this.props.lista.map(usuario => (
                        <Row key={usuario.id}>
                            <UsuarioCard
                                handleClick={(user) => this.setState({selectedUser: user, showModal: true})}
                                usuario={usuario}
                            />
                        </Row>
                    ))
                }
                {
                    this.state.showModal  ? 
                        <CriarEditarUsuario
                            show={this.state.showModal}
                            handleClose={() => this.setState({showModal: false})}
                            usuario={this.state.selectedUser}
                            onUserUpdate={this.props.onUserUpdate}
                        />
                    :
                        null
                }
            </Container>
        );
    }
}

export default UsuariosLista;
import { getAuthenticatedUser } from "./userService";

function onlyTrue(toBeTrue) {
  if(toBeTrue === 'undefined') return false
  if(toBeTrue === undefined) return false
  if(toBeTrue === null) return false
  if(!toBeTrue) return false
  return true
}

function trueList(list) {
  return !list.some(item => !onlyTrue(item))
}

export const AuthService = {
  async signIn(userInfo) {
    localStorage.setItem('jwt', userInfo?.tokenId);
    localStorage.setItem('userInfo', JSON.stringify(userInfo.profileObj));
    await getAuthenticatedUser();
    window.location.reload();
  },
  signOut() {
    window.sessionStorage.removeItem("access_token");
    window.sessionStorage.removeItem("nama");
    localStorage.removeItem('jwt');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('nexoInfo');
    window.location.replace('/');
  },
  isSignIn() {
    const jwt = localStorage.getItem('jwt');
    const userInfo = localStorage.getItem('userInfo');
    const nexoInfo = localStorage.getItem('nexoInfo');
    const isSign = trueList([jwt, userInfo, nexoInfo])
    return isSign
  },
  async getCurrentAuthenticatedUser(){
    try {
      const userInfo = localStorage.getItem('userInfo');
      return JSON.parse(userInfo);
    } catch (e) {
      return null;
    }
  },
};

import { enviromentVariables } from '../vars'
import axios from 'axios'
import { AuthService } from './AuthServices'

const defaultOptions = {
  baseURL: enviromentVariables.backofficeApiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  mode: 'no-cors',
}

function putAuthInRequest(config) {
  const token = localStorage.getItem('jwt')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
}

let myAxios = axios.create(defaultOptions)
let myNodeAxios = axios.create({ ...defaultOptions, baseURL: "http://localhost:3001/api" })

myAxios.interceptors.request.use(putAuthInRequest)
myAxios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error?.response?.status === 401) {
    AuthService.signOut()
  }
  return Promise.reject(error)
})
myNodeAxios.interceptors.request.use(putAuthInRequest)
myNodeAxios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error?.response?.status === 401) {
    AuthService.signOut()
  }
  return Promise.reject(error)
})

export { myAxios, myNodeAxios }

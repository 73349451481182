import React from 'react';
import {Container} from 'react-bootstrap';
import PrescricaoCard from '../PrescricaoCard';

class PrescricoesLista extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Container>
                {this.props.lista.map(prescricao => (
                    <PrescricaoCard 
                        key={prescricao.CodigoValidacao}
                        prescricao={prescricao}
                        onPrescriptionUpdate={this.props.onPrescriptionUpdate}
                    />
                ))}
            </Container>
        );
    }
}

export default PrescricoesLista;
import React from 'react';
import { Container, Row, Col, FormControl, InputGroup, Button } from 'react-bootstrap';
import Select from 'react-select';
import Loading from '../Loading';
import './styles.css';
import MedicosLista from '../MedicosLista';

const conselhos = [
    {label: 'CRM', value: 'CRM'},
    {label: 'CRP', value: 'CRP'},
    {label: 'COREN', value: 'COREN'},
    {label: 'CRO', value: 'CRO'},
    {label: 'CREFITO', value: 'CREFITO'},
];

const ufs = [
    {label: 'AC', value: 'AC'},
    {label: 'AL', value: 'AL'},
    {label: 'AP', value: 'AP'},
    {label: 'AM', value: 'AM'},
    {label: 'BA', value: 'BA'},
    {label: 'CE', value: 'CE'},
    {label: 'ES', value: 'ES'},
    {label: 'GO', value: 'GO'},
    {label: 'MA', value: 'MA'},
    {label: 'MT', value: 'MT'},
    {label: 'MS', value: 'MS'},
    {label: 'MG', value: 'MG'},
    {label: 'PA', value: 'PA'},
    {label: 'PB', value: 'PB'},
    {label: 'PR', value: 'PR'},
    {label: 'PE', value: 'PE'},
    {label: 'PI', value: 'PI'},
    {label: 'RJ', value: 'RJ'},
    {label: 'RN', value: 'RN'},
    {label: 'RS', value: 'RS'},
    {label: 'RO', value: 'RO'},
    {label: 'RR', value: 'RR'},
    {label: 'SC', value: 'SC'},
    {label: 'SP', value: 'SP'},
    {label: 'SE', value: 'SE'},
    {label: 'TO', value: 'TO'},
    {label: 'DF', value: 'DF'},
]



class BuscaMedico extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonEnabled: false,
            nome: null,
            conselho: null,
            numeroConselho: null,
            ufConselho: null,
        }
        this.handleInput = this.handleInput.bind(this);
        this.handleSearch =  this.handleSearch.bind(this);
    }

    handleInput(e, state){
        this.setState({[state]: e.target.value}, () => {
            const buttonEnabled = !!this.state.conselho || !!this.state.ufConselho || !!this.state.numeroConselho
             || !!this.state.nome;
            this.setState({buttonEnabled});
        });
    }

    handleSearch(){
        const obj = {
            uf: !!this.state.ufConselho ? this.state.ufConselho.value : null,
            numero: this.state.numeroConselho,
            conselho: !!this.state.conselho ? this.state.conselho.value : null,
            nome: this.state.nome
        }
        this.props.onDoctorUpdate(obj);
    }

    render() {
        return (
            <Container className="busca-prescricao-container" fluid>
                <Row style={{paddingBottom: '20px'}}>
                    <span>Busca de médicos na plataforma. Ao menos um campo é obrigatório:
                    </span>
                </Row>
                <Row className="justify-content-center">
                    <Col md='auto' sm='12' xs='auto'>
                        <div className='form-container'>
                            <InputGroup>
                                <FormControl
                                    placeholder='Nome'
                                    onChange = {(e) => this.handleInput(e,'nome')}
                                />                   
                            </InputGroup>
                        </div>
                    </Col>
                    <Col md='2' sm='12' xs='9'>
                        <div className='form-container'>
                            <Select 
                                options={conselhos} 
                                placeholder='Conselho'
                                value={this.state.conselho}
                                onChange={(e) => this.setState({conselho: e})}
                            />
                        </div>
                    </Col>
                    <Col md='auto' sm='12' xs='auto'>
                        <div className='form-container'>
                            <InputGroup>
                                <FormControl
                                    placeholder='Número'
                                    onChange = {(e) => this.handleInput(e,'numeroConselho')}
                                />   
                            </InputGroup>
                        </div>
                    </Col>
                    <Col md='2' sm='12' xs='9'>
                        <div className='form-container'>
                            <Select 
                                options={ufs} 
                                placeholder='UF'
                                value={this.state.ufConselho}
                                onChange={(e) => this.setState({ufConselho: e})}
                            />
                        </div>
                    </Col>
                    <Col md='auto' sm='12' xs='auto'>
                            <Button
                                className="btn"
                                variant="info"
                                block
                                onClick={this.handleSearch}
                                disabled={!this.state.buttonEnabled}
                                label={'BUSCAR'}>
                                    BUSCAR
                            </Button>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                </Row>
                <Row>
                    <Col>
                        <div style={{paddingTop: '10px'}}>
                            <h4>Resultados:</h4>
                        </div>
                        {
                            this.props.loading ?
                            <Loading />
                                :
                            <MedicosLista
                                lista={this.props.lista}
                                onDoctorUpdate={this.handleSearch}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default BuscaMedico;
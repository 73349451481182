import React, { useEffect, useState } from 'react'
import { Flex } from '../../App'
import SvgSearchFail from '../../assets/svg/SearchFail'
import { getSubscriptions } from '../../services/subscription'
import { FilterBox, statusToSearch, validateEmail, LineSearch, LineSearchSimplified } from './components'
import './styles.css'

export function SearchBox({ onSearch, disabled = () => true, loading, ...props }) {
    const [value, setValue] = useState()

    return (
        <Flex>
            <input
                className="my-input-search "
                value={value}
                onChange={key => setValue(key.target.value)}
                {...{ ...props }}
            />
            <button
                type="submit"
                className="my-button"
                onClick={() => onSearch(value)}
                disabled={!disabled(value)}
            >
                {loading ?
                (<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>) :
                'buscar'}
            </button>
        </Flex>
    )
}

function Farmacia({ history }) {
    const [searchStatusSelected, setSearchStatusSelected] = useState()
    const [subs, setSubs] = useState(e => e?.sort(e => e?.subscriptionDate ))
    const [searched, setSearched] = useState(false)

    useEffect(async () => {
        const subscriptions = await getSubscriptions({ status: 'ACTIVE' })
        setSubs(subscriptions)
    }, [])

    async function onSearch (customerEmail) {
        setSearchStatusSelected(null)
        setSubs([])
        setSearched('loading')
        const subscriptions = await getSubscriptions({ customerEmail })
        setSearched(true)
        setSubs(subscriptions)
    }

    const onClickSubscription = async (e) => {
        if (!e?.id) return
        window.location.replace('/dispensacao/' + e.id)
    }
    
    return (
        <div>
            {!searched && (
                <Flex style={{ flexDirection: 'column', alignItems: 'center', marginBottom: 50 }}>
                    <h1 style={{ fontSize: 30, fontWeight: 'bold', textAlign: 'center' }}>Encontre o assinante</h1>
                    <h2 style={{ fontSize: 18, fontWeight: 400, width: 476, textAlign: 'center' }}>Efetue a busca através do email do cliente para poder visualizar as informações da assinatura dele.</h2>
                </Flex>
            )}
            <SearchBox
                disabled={validateEmail}
                placeholder="Insira o email do assinante"
                loading={searched === 'loading'}
                {...{ onSearch }}
            />
            {searched && <FilterBox
                {...{ statusToSearch, setSearchStatusSelected, searchStatusSelected }}
            />}
            {searched === true && (!subs?.length || subs === null) && <Flex style={{ paddingTop: 150, justifyContent: 'center' }}><SvgSearchFail /></Flex>}
            {searched === true && 
                subs?.map(sub => (!searchStatusSelected || searchStatusSelected === sub?.status) && <LineSearch onClick={() => onClickSubscription(sub)} sub={sub} />)}
            {!searched && (
                <>
                    <h3 style={{ fontWeight: 'bold', fontSize: 16,  marginTop: 67, marginBottom: 20 }}>Aguardando análise do Farmacêutico</h3>
                    {typeof subs == 'object' && subs?.sort(e => e.createdAt)?.map(sub => (!searchStatusSelected || searchStatusSelected === sub?.status) && <LineSearchSimplified onClick={() => onClickSubscription(sub)} sub={sub} />)}
                </>
            )}
        </div>
    )
}

export default Farmacia

import React from 'react';
import {FormControl, Button, Modal} from 'react-bootstrap';
import './styles.css';
import { BsPencil, BsCheck, BsX } from 'react-icons/bs';
import { updateDoctorDocument } from '../../services/doctorService';

class MedicoCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmModal: false,
            editDocument: false,
            documentFormValue: null,
            loading: false,
        }
    }

    async handleConfirm(){
        this.setState({loading: true})
        if(!!this.state.documentFormValue){
            await updateDoctorDocument(this.props.medico.IdMedico,this.state.documentFormValue)
        }
        this.setState({loading:false, editDocument: false});
        this.props.onDoctorUpdate();
    }

    handleInput(e, state){
        this.setState({[state]: e.target.value});
    }

    
    formatConselho(){
        const conselho = `${this.props.medico.RegistroProfissional.Conselho}-${this.props.medico.RegistroProfissional.Numero}/${this.props.medico.RegistroProfissional.UF}`
        return conselho
    }

    render() {
        return (
            <div className='medico-card-container'>
                <div className='medico-card-title'>
                    <div>
                        <strong>Nome:</strong> {this.props.medico.Nome}
                    </div>
                    <div>
                        <strong>CRM:</strong> {this.formatConselho()}
                    </div>
                </div>
                <div className='medico-card-body'>
                    {
                        !this.state.editDocument ?
                        <div className='medico-card-body-item'>
                            <span>
                                <strong>Documento:  </strong>  {this.props.medico.Documento}
                            </span>
                            <span className='span-icon'>                                
                                <BsPencil 
                                    onClick={() => this.setState({editDocument: true})}
                                    className='icon-button'
                                />
                            </span>
                        </div>
                        :
                        <div className='medico-card-body-item-editing'>            
                            <FormControl
                                style={{maxWidth:'120px', marginLeft: '5px'}}
                                placeholder='Documento'
                                onChange = {(e) => this.handleInput(e,'documentFormValue')}
                            /> 
                            <span className='span-icon'>
                                <BsX 
                                        onClick={() => this.setState({editDocument: false})}
                                        className='icon-button'
                                />
                            </span>
                            <span className='span-icon'>
                                <BsCheck 
                                        onClick={() => this.setState({confirmModal: true})}
                                        className='icon-button'
                                />
                            </span>
                        </div>                  
                    }
                    
                    <div>
                        <strong>Especialidades:</strong> {this.props.medico.Especialidades}
                    </div>
                    <div>
                        <strong>Sexo:</strong> {this.props.medico.Sexo}
                    </div>
                </div>
                {
                    this.state.confirmModal ? 
                    <Modal show={this.state.confirmModal} onHide={() => this.setState({confirmModal: false})}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Confirmar edição?
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='medico-card-unlock-buttons'>
                                <Button variant="light" onClick={() => this.setState({confirmModal: false})}>
                                    CANCELAR
                                </Button>
                                <Button variant="success" onClick={() => this.handleConfirm()}>
                                    SIM
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                  :
                  null
                }
            </div>
        );
    }
}

export default MedicoCard;
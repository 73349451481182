import React from 'react';
import {Container, Row } from 'react-bootstrap';
import './styles.css';
import { AuthService } from '../../services/AuthServices';
import { GoogleLogout } from 'react-google-login';
const clientId = process.env.REACT_APP_CLIENT_ID;

class MeuCadastro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: ''
        }

    }
    async componentDidMount() {
        const user = await AuthService.getCurrentAuthenticatedUser();
        this.setState({username: user.name })
    }

    render() {
        return (
            <Container fluid className='meu-cadastro-container'>
                <Row>
                    <h5>
                        Olá, <strong>{this.state.username}!</strong> 
                    </h5>
                </Row>
                <Row>
                    <GoogleLogout
                        clientId={clientId}
                        buttonText="Deslogar"
                        onLogoutSuccess={() => AuthService.signOut()}
                    />
                </Row>
            </Container>
        );
    }
}

export default MeuCadastro;
import React from 'react';
import {Row, Col, FormControl, InputGroup, Button, Form, Modal} from 'react-bootstrap';
import './styles.css';
import Select from 'react-select';
import { createUser, editUser } from '../../services/userService';

const features = [
    {label: 'DISPENSAÇÃO', value: 'DISPENSAÇÃO'},
    {label: 'ATENDIMENTO', value: 'ATENDIMENTO'},
    {label: 'BASE', value: 'BASE'},
    {label: 'CREDENCIAIS', value: 'CREDENCIAIS'},
    {label: 'PDV', value: 'PDV'}
];

const formatFeatures = (features) => {
    return features.map(feature => ({
        label: feature,
        value: feature
    }));
}

class CriarEditarUsuario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nome: this.props.usuario ? this.props.usuario.nome : '',
            email: this.props.usuario ? this.props.usuario.email : '',
            isAdmin: this.props.usuario ? this.props.usuario.isAdmin : false,
            features: this.props.usuario ? formatFeatures(this.props.usuario.features) : [],
            loading: false
        }
        this.handleInput =  this.handleInput.bind(this);
        this.handleAdmin =  this.handleAdmin.bind(this);
        this.handleConfirm =  this.handleConfirm.bind(this);
    }
    componentDidMount() {

    }

    handleInput(e, state){
        this.setState({[state]: e.target.value});
    }

    handleAdmin(event){
        const on = event.target.checked;
        if(on){
            this.setState({isAdmin: true, features: features});
        } else {
            this.setState({isAdmin: false});
        }
    }

    async handleConfirm(){
        this.setState({loading: true});
        const userObj = {
            nome: this.state.nome,
            email: this.state.email,
            features: this.state.features.map(feature => feature.value),
            isAdmin: this.state.isAdmin
        }
        try {
            if (this.props.usuario != null){
                await editUser(this.props.usuario.id,userObj)
            } else {
                await createUser(userObj);
            }
            this.props.handleClose();
        } catch (err) {
            alert("Erro ao cocluir operação.", err.data);
        }
        this.setState({loading: false});
        this.props.onUserUpdate();
    }

    render() {
        const actionText = this.props.usuario != null? "EDITAR" : "CRIAR";
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                            <Modal.Title>
                                {actionText} usuário
                            </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md='auto' sm='auto' xs='12'>
                            <div className='form-container'>
                                <span>Email: </span>
                                <InputGroup>
                                    <FormControl
                                        value={this.state.email}
                                        disabled={this.props.usuario != null}
                                        placeholder='Email'
                                        onChange = {(e) => this.handleInput(e,'email')}
                                    />                   
                                </InputGroup>
                            </div>
                        </Col>
                        <Col md='auto' sm='auto' xs='12'>
                            <div className='form-container'>
                                <span>Nome: </span>
                                <InputGroup>
                                    <FormControl
                                        value={this.state.nome}
                                        placeholder='Nome'
                                        onChange = {(e) => this.handleInput(e,'nome')}
                                    />                   
                                </InputGroup>
                            </div>
                        </Col>
                        <Col md='auto' sm='12' xs='auto'>
                            <div className='form-container'>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md='auto' sm='12' xs='auto'>
                            <div className='form-container'>
                                <span>Administrador: </span>
                                <Form>
                                    <Form.Check 
                                        checked={this.state.isAdmin}
                                        type="switch"
                                        id="admin-switch"
                                        style={{width:'100px'}}
                                        onChange={this.handleAdmin}
                                    />
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md='12' sm='12' xs='12'>
                            <div className='form-container'>
                                <span>Permissões: </span>
                                <Select 
                                    isDisabled={this.state.isAdmin === true}
                                    isMulti
                                    options={features} 
                                    placeholder='Funcionalidades'
                                    value={this.state.features}
                                    onChange={(e) => this.setState({features: e})}
                                />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="light" 
                                onClick={this.props.handleClose}>
                            CANCELAR
                        </Button>
                        <Button variant="success" 
                                disabled={this.state.loading}
                                onClick={this.handleConfirm}>
                            {this.state.loading ? `${actionText}...` : actionText}
                        </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CriarEditarUsuario;
import React from 'react';
import BuscaMedico from '../../components/BuscaMedico';
import { searchDoctors } from '../../services/doctorService';

class BuscaMedicoContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lista: [],
            loading: false
        }
        this.getDoctors =  this.getDoctors.bind(this);
    }

    async getDoctors(searchObj){
        this.setState({loading: true});
        let resultados = []
        if(!!searchObj){
            try {
                resultados = await searchDoctors(searchObj);
            } catch(err){
                alert("Erro ao buscar médicos.")
            }
        }
        this.setState({lista: resultados, loading: false});
    }

    render() {
        return (
            <BuscaMedico
                lista={this.state.lista}
                loading={this.state.loading}
                onDoctorUpdate={this.getDoctors}
            />
        );
    }
}

export default BuscaMedicoContainer;